@import 'react-rg-designsystem/style/variables.scss';
@import './variables.scss';

// With swiper
.SceneListDesktop {
  position: relative;
  height: 100vh;
  
  .swiper-wrapper {
    height: 100vh;
  }

  .SceneSwiper.swiper.sceneWrapper,
  .PixelStreamingView {
    position: absolute;
    top: 0px;
    left: $toolbarWidth;
  
    width: calc(100% - $toolbarWidth);
    height: 100%;

    .SceneView {
      height: 100vh;
    }
  }
  .SceneSwiper.swiper.sceneWrapper {
    width: 200px;
    right: 0px;
    left: auto;
  }
}

// Without swiper
.SceneListDesktop,
.SceneListEditor {
  .sceneWrapper.noSwiper {
    position: relative;
    height: 100vh;

    .SceneView {
      position: absolute;
      top: 0px;
      left: $toolbarWidth;
    
      width: calc(100% - $toolbarWidth);
      height: 100%;
    }
  }
}