@import 'react-rg-designsystem/style/variables.scss';

.VariantText {
  &:not(:first-child) {
    margin-top: 20px;
  }

  h5, h6 {
    margin: 0px;
  }
}