@import 'react-rg-designsystem/style/variables.scss';

.CartSection {
  margin-top: 40px;

  // &:first-child {
  //   margin-top: 40px;
  // }
  
  h4 {
    margin: 0px 0px 4px;
  }

  @media print {
    &.page-break {
      page-break-inside: avoid;
    }
  }
}
