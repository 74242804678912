@import 'react-rg-designsystem/style/variables.scss';

html { 
  overflow: hidden;
}

.Viewer {
  
  &.desktop {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &.mobile {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .loading {
    color: $on-surface-high-emph;
  }
}