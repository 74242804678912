@import 'react-rg-designsystem/style/variables.scss';

.Option {
  height: 100%;

  display: flex;
  flex-direction: column;
  text-align: left;
  
  > .header {
    margin-bottom: 16px;
    flex-grow: 0;

    .description {
      display: block;
      box-sizing: border-box;
      padding-top: 10px;
    }
  }

  > .VariantList {
    flex-grow: 1;
  }

  // &:not(:last-child) {
  //   border-bottom-style: solid;
  //   border-bottom-width: 1px;
  //   border-color: $surface;
  // }

  h4 {
    margin: 0px;
  }
  p {
    margin: 10px 0px;
  }
}