// Import libraries
@import 'variables.scss';
@import "scrollbar.scss";
@import "swiper.scss";

/*
 * Usage
 * - Include this once in the root of your app.
 * - You can use the configurable variables anywhere.
 *   Please note that the compiler won't check if they actually exist.
 */

/* Configurable Variables */
html {
  /* Color styles */
  
  // Tend Brand

  --surface-brand: #EDE9E2;

  --primary-plus3: #89bfd9;
  --primary-plus2: #65a3c0;
  --primary-plus1: #488ead;
  --primary-zero: #247a9b;
  --primary-minus1: #116b89;
  --primary-minus2: #005773;
  --primary-minus3: #00445d;
  --primary-minus4: #003045;

  --secondary-plus3: #ada4c6;
  --secondary-plus2: #8d80ae;
  --secondary-plus1: #76669d;
  --secondary-zero: #604e8d;
  --secondary-minus1: #594887;
  --secondary-minus2: #4e407d;
  --secondary-minus3: #463a73;
  --secondary-minus4: #39305e;
  
  --success-plus3-field: #80d2c7;
  --success-plus1-on-surface: #49bfaf;
  --success-zero-base: #10a593;

  --warning-plus3-field: #f3e97e;
  --warning-plus1-on-surface: #dccd44;
  --warning-zero-base: #a89330;

  --error-plus3-field: #f99797;
  --error-plus1-on-surface: #fe4848;
  --error-zero-base: #d6001d;

  // Raumgleiter Brand

  // --surface-brand: rgba(237, 237, 237, 1);

  // --primary-plus3: rgba(225, 239, 250, 1);
  // --primary-plus2: rgba(188, 220, 249, 1);
  // --primary-plus1: rgba(106, 177, 241, 1);
  // --primary-zero: rgba(56, 146, 235, 1);
  // --primary-minus1: rgba(52, 132, 221, 1);
  // --primary-minus2: rgba(47, 114, 202, 1);
  // --primary-minus3: rgba(42, 97, 184, 1);
  // --primary-minus4: rgba(34, 67, 152, 1);

  // --secondary-plus3: rgba(238, 226, 250, 1);
  // --secondary-plus2: rgba(207, 175, 248, 1);
  // --secondary-plus1: rgba(176, 119, 248, 1);
  // --secondary-zero: rgba(152, 73, 249, 1);
  // --secondary-minus1: rgba(130, 20, 245, 1);
  // --secondary-minus2: rgba(118, 12, 239, 1);
  // --secondary-minus3: rgba(101, 0, 231, 1);
  // --secondary-minus4: rgba(84, 0, 226, 1);

  // --success-plus3-field: rgba(225, 250, 244, 1);
  // --success-plus1-on-surface: rgba(64, 222, 182, 1);
  // --success-zero-base: rgba(0, 205, 154, 1);

  // --warning-plus3-field: rgba(250, 244, 226, 1);
  // --warning-plus1-on-surface: rgba(255, 231, 17, 1);
  // --warning-zero-base: rgba(253, 187, 0, 1);

  // --error-plus3-field: rgba(250, 226, 228, 1);
  // --error-plus1-on-surface: rgba(252, 48, 52, 1);
  // --error-zero-base: rgba(237, 35, 51, 1);
    
  --functional-a: rgba(255, 142, 36, 1);
  --functional-b: rgba(56, 146, 235, 1);
  --functional-c: rgba(158, 242, 41, 1);
  --functional-d: rgba(159, 96, 239, 1);
  --functional-e: rgba(250, 55, 110, 1);

  /* Typography styles */
  --h1-family: $title-family;
  --h1-transform: none;
  --h1-line-height: 76px;
  --h1-letter-spacing: 0em;
  --h1-weight: 900;

  --h2-family: $title-family;
  --h2-transform: none;
  --h2-line-height: 34px;
  --h2-letter-spacing: 0em;
  --h2-weight: 400;

  --h3-family: $title-family;
  --h3-transform: none;
  --h3-line-height: 24px;
  --h3-letter-spacing: 0em;
  --h3-weight: 400;

  --h4-family: $subtitle-family;
  --h4-transform: none;
  --h4-line-height: 19px;
  --h4-letter-spacing: 0em;
  --h4-weight: 600;

  --h5-family: $subtitle-family;
  --h5-transform: none;
  --h5-line-height: 19px;
  --h5-letter-spacing: 0em;
  --h5-weight: 400;

  --h6-family: $subtitle-family;
  --h6-transform: none;
  --h6-line-height: 14px;
  --h6-letter-spacing: 0em;
  --h6-weight: 400;

  --text-family: $running-text-family;
  --text-transform: none;
  --text-line-height: 17px;
  --text-letter-spacing: 0em;
  --text-weight: 400;

  --finePrint-family: $running-text-family;
  --finePrint-transform: none;
  --finePrint-line-height: 14.5px;
  --finePrint-letter-spacing: 0em;
  --finePrint-weight: 400;  
}

body {
  background-color: $surface;
  margin: 0px;
  padding: 0px;
}

/* Font */
* {
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $running-text-family;
}

/* Text-size styles */
h1 { @include RGHeader1; }
h2 { @include RGHeader2; }
h3 { @include RGHeader3; }
h4 { @include RGHeader4; }
h5 { @include RGHeader5; }
h6 { @include RGHeader6; }
span, p { @include RGText; }
span.fine, p.fine { @include RGFinePrint; }

button {
  border: none;
}

a {
  color: $on-elevation-link;
  text-decoration: none;
}
a.high-emph {
  text-decoration: underline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.warning {
  color: var(--warning-zero-base);
}
.error {
  color: var(--error-zero-base);
}

.backdrop {
  background-color: $backdrop;
  width: 100%;
  height: 100%;
}

.separator {
  width: 100%;
  height: 4px;
  background-color: $surface;

  margin: 20px 0px;

  &.slim {
    height: 1px;
  }
}

.ds-on-elevation {
  color: $on-elevation-high-emph;
}
.ds-on-elevation.med-emph {
  color: $on-elevation-med-emph-light;
}
.ds-on-surface {
  color: $on-surface-high-emph;
}
.ds-on-surface.med-emph{
  color: $on-surface-med-emph-light;
}
