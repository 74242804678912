@import 'react-rg-designsystem/style/variables.scss';

.ImageStructure {
  margin-left: auto;
  margin-right: auto;
  // margin-left: 100px;
  // margin-right: 100px;
  width: auto;
  min-width: 620px;

  color: $on-surface-high-emph;
  text-align: left;

  display: inline-block;

  .content {
    display: flex;
    flex-direction: row;
  }

  .RGFormContainer {
    padding: 20px 40px;
    color: $on-elevation-high-emph;
    width: 100%;

    &:not(:first-child) {
      margin-top: 0px;
      margin-left: 1px;
    }
  }

  .RGFormSegment {
    h4 {
      display: inline-block;
      margin: 0px 6px 0px 0px;
    }
    .error {
      color: var(--error-zero-base);
    }
    .success {
      color: var(--success-zero-base);
    }

    .validationEntry {
      margin-bottom: 8px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;

      gap: 40px;
    }
  }

  .FeedbackExplanation {
    max-width: 540px;

    padding-bottom: 20px;
    margin-bottom: 20px;
    
    &::after {
      content: '';
      position: absolute;
      left: -40px;
      width: calc(100% + 80px);
      transform: translateY(20px);

      height: 1px;
      background-color: $on-elevation-high-emph;
    }
  }
}