@import 'react-rg-designsystem/style/variables.scss';

.AccessCode {
  box-sizing: border-box;
  width: 100%;
  background-color: $elevation-minus2-field;
  
  padding: 20px;

  > .codePlaceholder {
    position: relative;
    height: 51px;
    width: 132px;
    margin-left: auto;
    margin-right: auto;

    > div {
      position: absolute;
      background-color: $on-elevation-placeholder;
    }
    :nth-child(1) {
      height: 14px;
      width: 108px;
      left: 0px;
      top: 2px;
    }
    :nth-child(2) {
      height: 28px;
      width: 108px;
      left: 0px;
      top: 21px;
    }
    :nth-child(3) {
      height: 19px;
      width: 19px;
      right: 0px;
      top: 26px;
    }
  }

  > div.content {
    width: 140px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    
    .code {
      display: flex;
      flex-direction: row;
      gap: 8px;

      h2, button {
        // display: inline-block;
        margin-bottom: 0px;
      }

      h2 {
        margin-top: 0px;
      }

      button {
        // margin-left: 8px;
        transform: translateY(5px);
      }
    }
  }

  button {
    width: 28px;
    height: 28px;

    background-color: $elevation-minus2-field;
    border-radius: 999px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    .faIcon {
      font-size: 16px;
      color: var(--primary-zero);
      opacity: 60%;
    }

    &:hover {
      background-color: $elevation-minus1-area;

      .faIcon {
        opacity: 100%;
      }
    }
    &:active {
      background-color: $elevation-zero-base;

      .faIcon {
        color: var(--primary-minus2);
      }
    }
  }

}
