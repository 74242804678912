@import 'react-rg-designsystem/style/variables.scss';

.ContractDocument {
  position: relative;

  margin-left: auto;
  margin-right: auto;
  
  box-sizing: border-box;
  width: 1000px;
  min-height: 1414px;
  background-color: $elevation-zero-base;
  
  padding-bottom: 100px;

  .RGBrandHeader {
    text-align: center;
  }
  .Cart {
    // margin-top: 100px;
    margin-top: 40px;
  }
  .SceneView.floorplan {
    position: relative;
    height: 268px;
    width: auto;

    .swiper-slide {
      background-color: transparent;
    }
  }
  .Floorplan {
    box-sizing: border-box;
    height: 1414px;
  }
}

@page {
  // Page 2 ans so on
  size: auto;
  margin: 20mm 0mm 10mm 0mm;
}
@page:first {
  // Page 1
  margin: 0mm 0mm 10mm 0mm;
}
@-moz-document url-prefix() {
  @page {
    // Firefox: All pages
    margin: 20mm 0mm 10mm 0mm;
  }
}
