::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-track {
  background: $elevation-minus3-field-selection;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 4px solid $elevation-minus3-field-selection;
  background: $elevation-zero-base;
  
  &:hover {
    background: $elevation-minus8-indent;
  }
}

@mixin scrollbars() {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $elevation-minus3-field-selection;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid $elevation-minus3-field-selection;
    background: $elevation-zero-base;
    
    &:hover {
      background: $elevation-minus8-indent;
    }
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $elevation-minus3-field-selection;
    scrollbar-track-color: $elevation-minus3-field-selection;
  }

  // For Firefox
  & {
    scrollbar-color: $elevation-zero-base $elevation-minus3-field-selection;
    scrollbar-width: 16px;
  }
}