@import 'react-rg-designsystem/style/variables.scss';

.ToolbarHeader {
  background-color: $elevation-zero-base;

  .seperator {
    min-height: 1px;
    width: 100%;
    background-color: $elevation-minus8-indent;
  }
  .RGFormSection {
    height: auto;
    background-color: $elevation-zero-base;

    h3 {
      margin: 10px 0px;
      text-align: left;
    }
  }

  
  .description {
    box-sizing: border-box;

    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: left;

    span {
    }
  }
}