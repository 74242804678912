html { 
  overflow: auto;
}

.Home {
  .content {
    max-width: 400px;
  }
  .cta {
    margin-top: 30px;

    .RGButton {
      margin-left: 0px;
      min-width: 317px;
    }
  }
}