@import 'react-rg-designsystem/style/variables.scss';

.CartScreen.desktop {
  box-sizing: border-box;
  width: 100%;
  // min-height: 100%;
  height: 100%;
  // overflow-x: hidden;
  overflow-y: auto;

  background-color: $elevation-zero-base;

  padding-bottom: 40px;

  .RGContainerButton.close {
    position: fixed;
    top: 30px;
    right: 40px;
    z-index: 100;
  }
  .Cart {
    box-sizing: border-box;
    max-width: 700px;
    margin-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  // .ConfigurationActions {
  //   margin-bottom: 80px;
  // }
}
