@import 'react-rg-designsystem/style/variables.scss';
@import './variables.scss';

.SceneToolbar {
  position: relative;
}

.SceneToolbar.desktop {
  width: $toolbarWidth;
  box-shadow: $shadow-plus1-expanded-pressed;

  z-index: 1;

  .RGSidebarLeftList {    
    .RGCardScroller {
      flex-grow: 1;

      .RGFormContainer {
        min-height: 100%;
      }
    }
  }
}