/*
 * Usage
 * - Include this wherever you want to use these variables.
 * - Configurable css variables are defined in defaults.scss!
 */

/* Color styles */
$surface: rgba(0, 0, 0, 1);

$on-surface-high-emph: rgba(255, 255, 255, 1);
$on-surface-med-emph-light: rgba(255, 255, 255, 0.6000000238418579);
$on-surface-med-emph-bold: rgba(255, 255, 255, 0.44999998807907104);
$on-surface-disabled: rgba(255, 255, 255, 0.6499999761581421);
$on-surface-placeholder: rgba(255, 255, 255, 0.2);
$on-surface-link: rgba(56, 146, 235, 1);

$elevation-zero-base: rgba(255, 255, 255, 1);
$elevation-minus1-area: rgba(247, 247, 247, 1);
$elevation-minus2-field: rgba(237, 237, 237, 1);
$elevation-minus3-field-selection: rgba(225, 225, 225, 1);
$elevation-minus8-indent: rgba(151, 151, 151, 1);
$elevation-minus10-indent: rgba(114, 114, 114, 1);
$elevation-minus70-field-selected: rgba(64, 64, 64, 1);
$elevation-minus80-panel: rgba(24, 24, 24, 1);

$on-elevation-high-emph: rgba(0, 0, 0, 1);
$on-elevation-med-emph-light: rgba(0, 0, 0, 0.6000000238418579);
$on-elevation-med-emph-bold: rgba(0, 0, 0, 0.44999998807907104);
$on-elevation-disabled: rgba(0, 0, 0, 0.6499999761581421);
$on-elevation-placeholder: rgba(0, 0, 0, 0.1);
$on-elevation-link: var(--primary-zero);

$on-primary-high-emph: rgba(255, 255, 255, 1);
$on-primary-med-emph-light: rgba(255, 255, 255, 0.6000000238418579);
$on-primary-med-emph-bold: rgba(255, 255, 255, 0.44999998807907104);
$on-primary-disabled: rgba(255, 255, 255, 0.6499999761581421);
$on-primary-placeholder: rgba(255, 255, 255, 0.2);

$on-secondary-high-emph: rgba(255, 255, 255, 1);
$on-secondary-med-emph-light: rgba(255, 255, 255, 0.6000000238418579);
$on-secondary-med-emph-bold: rgba(255, 255, 255, 0.44999998807907104);
$on-secondary-disabled: rgba(255, 255, 255, 0.6499999761581421);
$on-secondary-placeholder: rgba(255, 255, 255, 0.2);

$backdrop: rgba(0, 0, 0, 0.7200000286102295);
$content-overlay: rgba(255, 255, 255, 0.4);
@mixin content-overlay {
  background-color: $content-overlay;
  backdrop-filter: blur(8px);
}

$develop-opaque: rgba(250, 55, 110, 1);
$develop-element: rgba(250, 55, 110, 0.6);
$development-area: rgba(250, 55, 110, 0.25);

/* Effect styles */
$shadow-plus10-header:  0px 2px 9px rgba(0, 0, 0, 0.5);
// $shadow-plus1-expanded:  0px 3px 4px rgba(0, 0, 0, 0.5);
$shadow-plus2-expanded: 0px 2px 6px 0px hsla(0, 0%, 0%, 0.15), 0px 4px 4px 0px hsla(0, 0%, 0%, 0.25);
$shadow-plus1-expanded-pressed: 0px 2px 6px 0px hsla(0, 0%, 0%, 0.15);
$shadow-zero-base:  0px 3px 3px rgba(0, 0, 0, 0.5);

$border: 8px;

/* Grid styles */
$grid-side: 94px;
$grid-column-gutter: 24px;
$grid-card-padding: 20px;

/*
 * Font mixins
 * - Use these to make any header style look like the other.
 * - This is useful for SEO and accessibility.
 */
@mixin RGHeader1 {
  // font-size: 64px;
  font-size: 52px;

  font-family: var(--h1-family);
  font-weight: var(--h1-weight);
  text-transform: var(--h1-transform);
  line-height: var(--h1-line-height);
  letter-spacing: var(--h1-letter-spacing);
}
@mixin RGHeader2 {
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 20px;

  font-family: var(--h2-family);
  font-weight: var(--h2-weight);
  text-transform: var(--h2-transform);
  line-height: var(--h2-line-height);
  letter-spacing: var(--h2-letter-spacing);
}
@mixin RGHeader3 {
  font-size: 20px;

  font-family: var(--h3-family);
  font-weight: var(--h3-weight);
  text-transform: var(--h3-transform);
  line-height: var(--h3-line-height);
  letter-spacing: var(--h3-letter-spacing);
}
@mixin RGHeader4 {
  font-size: 16px;

  font-family: var(--h4-family);
  font-weight: var(--h4-weight);
  text-transform: var(--h4-transform);
  line-height: var(--h4-line-height);
  letter-spacing: var(--h4-letter-spacing);
}
@mixin RGHeader5 {
  font-size: 16px;

  font-family: var(--h5-family);
  font-weight: var(--h5-weight);
  text-transform: var(--h5-transform);
  line-height: var(--h5-line-height);
  letter-spacing: var(--h5-letter-spacing);
}
@mixin RGHeader6 {
  font-size: 12px;
  margin: 0px 0px 4.5px;
  color: $on-elevation-med-emph-light;

  font-family: var(--h6-family);
  font-weight: var(--h6-weight);
  text-transform: var(--h6-transform);
  line-height: var(--h6-line-height);
  letter-spacing: var(--h6-letter-spacing);
}
@mixin RGText {
  font-size: 14px;
  margin: 0px;

  font-family: var(--text-family);
  font-weight: var(--text-weight);
  text-transform: var(--text-transform);
  line-height: var(--text-line-height);
  letter-spacing: var(--text-letter-spacing);
}
@mixin RGFinePrint {
  font-size: 12px;

  font-family: var(--finePrint-family);
  font-weight: var(--finePrint-weight);
  text-transform: var(--finePrint-transform);
  line-height: var(--finePrint-line-height);
  letter-spacing: var(--finePrint-letter-spacing);
}

/* Font styles */
@font-face {
  font-family: MatterSQ;
  src:
    url("https://d33wubrfki0l68.cloudfront.net/64fd4ec77eb21c37862b7f1febee3f3164a6f6ef/1716c/_nuxt/fonts/matterraumgleiter-regular.44f8bf9.woff2")
    format("woff2"),url("https://d33wubrfki0l68.cloudfront.net/ddd8b64144c7aa24888acdb98551f02e76f98b36/7b39f/_nuxt/fonts/matterraumgleiter-regular.ada4df3.woff")
    format("woff");
    font-weight:400;
    font-style:normal;
    font-display:swap;
    unicode-range:u+000-5ff
}
@font-face{
  font-family:MatterSQ;
  src:
    url("https://d33wubrfki0l68.cloudfront.net/8204e0754a2b6ebf1ef33475340c615dfa9f1e2a/c5008/_nuxt/fonts/matterraumgleiter-semibold.a9cc51b.woff2")
    format("woff2"),
    url("https://d33wubrfki0l68.cloudfront.net/d83f15768c4592984c3820319ec5d862bcdd2513/f2c0d/_nuxt/fonts/matterraumgleiter-semibold.4d0fb6b.woff")
    format("woff");
    font-weight:700;
    font-style:normal;
    font-display:swap;
    unicode-range:u+000-5ff
}
@font-face{
  font-family:MatterSQ;
  src:
    url("https://d33wubrfki0l68.cloudfront.net/f9ecb08e763e5043338e65ce81c3fb9a2469118a/a9395/_nuxt/fonts/matterraumgleiter-heavy.1288088.woff2")
    format("woff2"),
    url("https://d33wubrfki0l68.cloudfront.net/a5f4fd6d36ce5ac18ff8c065a2b166cc112bdfeb/883c1/_nuxt/fonts/matterraumgleiter-heavy.e9022da.woff")

    format("woff");
    font-weight:900;
    font-style:normal;
    font-display:swap;
    unicode-range:u+000-5ff
}

$title-family: MatterSQ,-apple-system,sans-serif;
$subtitle-family: MatterSQ,-apple-system,sans-serif;
$running-text-family: MatterSQ,-apple-system,sans-serif;

html {
  --surface: rgba(0, 0, 0, 1);

  --on-surface-high-emph: rgba(255, 255, 255, 1);
  --on-surface-med-emph-light: rgba(255, 255, 255, 0.6000000238418579);
  --on-surface-med-emph-bold: rgba(255, 255, 255, 0.44999998807907104);
  --on-surface-disabled: rgba(255, 255, 255, 0.6499999761581421);
  --on-surface-placeholder: rgba(255, 255, 255, 0.2);
  --on-surface-link: rgba(56, 146, 235, 1);

  --elevation-zero-base: rgba(255, 255, 255, 1);
  --elevation-minus1-area: rgba(247, 247, 247, 1);
  --elevation-minus2-field: rgba(237, 237, 237, 1);
  --elevation-minus3-field-selection: rgba(225, 225, 225, 1);
  --elevation-minus8-indent: rgba(151, 151, 151, 1);
  --elevation-minus10-indent: rgba(114, 114, 114, 1);
  --elevation-minus70-field-selected: rgba(64, 64, 64, 1);
  --elevation-minus80-panel: rgba(24, 24, 24, 1);

  --on-elevation-high-emph: rgba(0, 0, 0, 1);
  --on-elevation-med-emph-light: rgba(0, 0, 0, 0.6000000238418579);
  --on-elevation-med-emph-bold: rgba(0, 0, 0, 0.44999998807907104);
  --on-elevation-disabled: rgba(0, 0, 0, 0.6499999761581421);
  --on-elevation-placeholder: rgba(0, 0, 0, 0.1);
  --on-elevation-link: var(--primary-zero);

  --on-primary-high-emph: rgba(255, 255, 255, 1);
  --on-primary-med-emph-light: rgba(255, 255, 255, 0.6000000238418579);
  --on-primary-med-emph-bold: rgba(255, 255, 255, 0.44999998807907104);
  --on-primary-disabled: rgba(255, 255, 255, 0.6499999761581421);
  --on-primary-placeholder: rgba(255, 255, 255, 0.2);

  --on-secondary-high-emph: rgba(255, 255, 255, 1);
  --on-secondary-med-emph-light: rgba(255, 255, 255, 0.6000000238418579);
  --on-secondary-med-emph-bold: rgba(255, 255, 255, 0.44999998807907104);
  --on-secondary-disabled: rgba(255, 255, 255, 0.6499999761581421);
  --on-secondary-placeholder: rgba(255, 255, 255, 0.2);

  --backdrop: rgba(0, 0, 0, 0.7200000286102295);
  --content-overlay: rgba(255, 255, 255, 0.4);

  --develop-opaque: rgba(250, 55, 110, 1);
  --develop-element: rgba(250, 55, 110, 0.6);
  --development-area: rgba(250, 55, 110, 0.25);

  --shadow-plus2-expanded: hsla(0, 0%, 0%, 0.25);
  --shadow-plus1-expanded-pressed: hsla(0, 0%, 0%, 0.15);
  --shadow-zero-base: rgba(0, 0, 0, 0.5);

  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-10: 10px;
  --spacing-20: 20px;
  --spacing-30: 30px;
  --spacing-40: 40px;
  --spacing-60: 60px;
  --spacing-80: 80px;
  --spacing-120: 120px;
}