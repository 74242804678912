@import 'react-rg-designsystem/style/variables.scss';

.VariantButton {
  position: relative;
  
  // Disable button changes
  background: none;
  box-sizing: content-box;
  padding: 0;

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  &.selected .border {
    border-radius: 8px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);

    border-style: solid;
    border-color: $elevation-zero-base;
    border-width: 2px;

    outline-style: solid;
    outline-color: var(--primary-zero);
    outline-width: 2px;
  }

  &:not(.selected):hover .border {
    // border-radius: 10px;
    // width: 100%;
    // height: 100%;
    // top: -2px;

    border-radius: 6px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin-left: auto;
    margin-right: auto;

    outline-style: solid;
    outline-color: var(--primary-zero);
    outline-width: 2px;

    img {
      border-radius: 6px;
    }
  }

  &:not(.selected):active .border {
    // border-radius: 10px;
    // width: 100%;
    // height: 100%;
    // top: -2px;

    border-radius: 6px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin-left: auto;
    margin-right: auto;

    outline-style: solid;
    outline-color: var(--primary-zero);
    outline-width: 4px;

    img {
      border-radius: 6px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}
