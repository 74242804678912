@import 'react-rg-designsystem/style/variables.scss';

.Floorplan {
  text-align: left;
  padding: 80px 80px;

  display: flex;
  flex-direction: column;

  border-top: 1px solid $surface;
  @media print {
    border-top: 0;
  }

  .CartSection {
    margin-top: 0px;
    flex-grow: 0;

    h4 {
      @include RGHeader2;
      margin: 0px 0px 8px;
    }

    .CartOption {
      > :first-child {
        width: 200px;
      }
    }
  }

  .SceneView {
    flex-grow: 1;
  }
}