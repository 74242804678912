@import 'react-rg-designsystem/style/variables.scss';
@import './utility.scss';

.OnboardingScreen {
  height: 100%;

  .RGSidebarLeftList {
    width: 260px;

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding-top: 26px;

      .RGButton {
        width: 145px;
      }
    }
  }

  &.desktop {
    width: 100%;
    overflow: hidden;

    .MoodImage {
      position: absolute;
      top: 0;
      left: 260px;
      right: 0;
      height: 100%;
    }
    .optionContainer {
      width: 100%;
      padding-top: 4px;

      background-color: $elevation-minus1-area;
    }
  }
  
  &.mobile {
    display: flex;
    flex-direction: column;

    .page.designLine {
      background-color: $elevation-zero-base;
      width: 100%;
      height: 58px;
      text-align: left;
      padding: 0 31px;

      h3 {
        margin-top: 17px;
      }
    }
  
    // .emptyBrandHeader {
    //   width: 100%;
    //   height: 58px;
    //   background-color: var(--surface-brand);
    // }
    
    .MoodImage {
      flex-grow: 1;
    }

    @include MobileBottomPanelOnboarding;
  }
}
