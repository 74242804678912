.CartOption {
  td {
    padding: 0;
    line-height: 24px;
    text-align: left;
    vertical-align: top;
  }
}

.Cart.full {
  .CartOption {
    > :first-child {
      width: 200px;
    }
    &.hasPrice > :nth-child(2) {
      width: 300px;
    }
    &.noPrice > :nth-child(2) {
      width: 420px;
    }
    > :nth-child(3) {
      width: 120px;
      text-align: right;
    }
  }
}

.Cart.slim {
  .CartOption {
    // &.noPrice > :first-child {
    //   width: 100%;
    // }
    > :nth-child(2) {
      width: 100px;
      text-align: right;
    }

    h6 {
      margin-bottom: 2px;
    }
  }
}