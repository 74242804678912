.desktop .PixelStreamingView {
  width: 100%;
  height: 100%;
}

.mobile .PixelStreamingView {
  // This is a hack to fill the height,
  // instead either the streaming should be adjusted
  // or the width should be set to the aspect ratio.
  // This should work though for all known cases.
  width: 300%;

  height: 100%;
  align-self: center;
}