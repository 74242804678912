@import 'react-rg-designsystem/style/variables.scss';

.SaveScreen {
  z-index: 10;

  .RGFormContainer {
    max-width: 400px;

    .RGButton {
      margin-left: auto;
      right: 0px;

      // margin-bottom: 30px;
    }
  }
}
