@import 'react-rg-designsystem/style/variables.scss';

.CartTotal {
  margin-top: 40px;
  margin-left: -20px;
  width: 100%;

  background-color: $elevation-minus2-field;
  box-shadow: inset 0 0 0 1000px $elevation-minus2-field;

  padding: 20px;

  .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h4 {
      margin: 0px 0px 20px;
    }
  }
  p, span {
    width: 500px;
  }
}