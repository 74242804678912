.ContractHeader {
  box-sizing: border-box;
  margin: 40px 80px 0px;
  width: calc(100% - 160px);

  display: flex;
  flex-direction: row;

  .identifier {
    text-align: left;

    h2 {
      margin: 0px 0px 14px;
    }
    p, span {
      line-height: 20px;
    }
  }
  .spacer {
    flex-grow: 1;
  }
  .access {
    display: flex;
    flex-direction: row;

    .text {
      margin-right: 20px;
      text-align: right;

      h4 {
        margin: 4px 0px 0px;
      }
    }
  }
}