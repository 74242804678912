.ContractSignature {
  width: 620px;
  margin: 40px auto 40px;
  
  text-align: left;
  
  .signatures {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
}