@import './variables.scss';

.swiper.swiper-horizontal {
  height: 100%;
  .swiper-wrapper {
    height: 100%;
    .swiper-slide {
      height: 100% !important;
    }
  }
}

.swiper.swiper-vertical {
  .RGPagination {
    position: relative;
    
    .label {
      pointer-events: none;
      opacity: 0.0;

      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      background-color: $elevation-zero-base;
      padding: 4px 10px;
      white-space: nowrap;
      border-radius: 4px;

      @include RGHeader4;
    }
    &.swiper-pagination-bullet-active .label {
      right: 19px;
    }
  }
  &:hover .RGPagination {
    &:hover .label,
    &.swiper-pagination-bullet-active .label {
      opacity: 1.0;
      transition: opacity 0.2s;
    }
  }

  .RGSlideshowNavigation {
    opacity: 0.0;
  }

  &:hover {
    .swiper-pagination.swiper-pagination-vertical {
      background-color: $elevation-zero-base;
      transition: background-color 0.2s;
      
      &.swiper-pagination-vertical {
        right: 20px !important;
    
        .swiper-pagination-bullet {
          &:first-child {
            margin-top: 0px !important;
          }
          &:last-child {
            margin-bottom: 0px !important;
          }
        }
      }
    }

    .RGSlideshowNavigation {
      opacity: 1.0;
      transition: opacity 0.2s;
    }
  }
}

.mobile {
  .swiper.swiper-horizontal {
    .swiper-pagination-horizontal {
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      
      width: auto !important;
      height: auto !important;
      background-color: $elevation-zero-base;

      display: flex;
      gap: 10px;

      .swiper-pagination-bullet {
        margin: 0px;
      }
    }
  }
}

.swiper-pagination {
  @include content-overlay;
  
  border-radius: 32px;
  padding: 0px;
  
  .swiper-pagination-bullet {  
    border-color: rgba(255, 255, 255, 0);
    border-style: solid;
    background-clip: padding-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;

    width: 4px;
    height: 4px;
    color: $on-elevation-high-emph;
    opacity: 1.0 !important;
    border-width: 5px;

    &.swiper-pagination-bullet-active {
      height: 12px;
      width: 12px;
      background-color: var(--primary-zero);
      opacity: 1.0 !important;
      border-width: 2px;
    }
  }

  &.swiper-pagination-vertical {
    right: 20px !important;

    .swiper-pagination-bullet {
      margin: 6px auto !important;

      &:first-child {
        margin-top: 0px !important;
      }
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
}