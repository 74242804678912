@import 'react-rg-designsystem/style/variables.scss';

.Signature {
  margin-top: 120px;
  width: 100%;

  .line {
    width: 100%;
    border-bottom: 1px solid $on-elevation-high-emph;
  }
  p, span {
    margin-top: 4px;
    text-align: left;
  }
}